<template>
  <div class="distanceDropdown">
    <InputDropdown
      :key="`dropdown${selectedItem.id}`"
      :model-value="selectedItem"
      :items="processedItems"
      :expand-upwards="expandUpwards"
      label="Radius"
      :disabled="disabled"
    />
  </div>
</template>

<script setup lang="ts">
import { IDropdownItem } from '~/types/dropdown'

type DistanceValues = 10 | 25 | 50 | 100 | 5000

interface IDistance {
  label: string
  value: DistanceValues
}

defineProps({
  expandUpwards: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const distanceSelected = defineModel({
  type: Number as PropType<DistanceValues>,
  required: true
})

const distanceOptions = ref<IDistance[]>([
  {
    label: '10 miles',
    value: 10
  },
  {
    label: '25 miles',
    value: 25
  },
  {
    label: '50 miles',
    value: 50
  },
  {
    label: '100 miles',
    value: 100
  },
  {
    label: 'National',
    value: 5000
  }
])

const selectedItem = computed<IDropdownItem>(() => {
  const option = distanceOptions.value.find(
    ({ value }) => distanceSelected.value === value
  )

  return {
    id: `distanceDropdown${option?.value || 25}`,
    text: option?.label || '25 miles'
  }
})

const processedItems = computed<IDropdownItem[]>(() =>
  distanceOptions.value.map((option) => ({
    id: `distanceDropdown${option.value}`,
    text: option.label,
    onSelect: () => {
      distanceSelected.value = option.value
    }
  }))
)
</script>

<style lang="scss">
.distanceDropdown {
  .dropdownItem--trigger {
    height: 56px;
  }

  .dropdown__items {
    max-height: none;
  }
}
</style>
